import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import {h5BaseStyle} from './Typography';
import FacebookLogo from '../../images/social-media/facebook.svg';
import LinkedinLogo from '../../images/social-media/linkedin.svg';
import TwitterLogo from '../../images/social-media/twitter.svg';
import {colors} from '../../styles/theme';


/*
 * Private Elements
 */
const StyledShareContainer = styled.div`
    ${h5BaseStyle};

    margin: 15px 0;

    ul {
        padding: 0;
    }

    li {
        display: inline-block;
    }
    li + li {
        margin-left: 30px;
    }

    svg {
        &:hover {
            * {
              fill: ${colors.darkGrey};
            }
        }
    }
`;


/*
 * Public Elements
 */
const ShareContentButtons = ({displayText, shareUrl, shareText}) => {
    const {t} = useTranslation();

    const facebookShare = `https://www.facebook.com/sharer.php?u=${shareUrl}`;
    const linkedinShare = `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`;
    const twitterShare = `https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareText}&via=switchpayments`;

    return (
        <StyledShareContainer className="share">
            <div>{displayText}</div>
            <ul>
                <li>
                    <a href={facebookShare} title={t('Share on Facebook')} target="_blank" rel="noreferrer">
                        <FacebookLogo />
                    </a>
                </li>
                <li>
                    <a href={linkedinShare} title={t('Share on LinkedIn')} target="_blank" rel="noreferrer">
                        <LinkedinLogo />
                    </a>
                </li>
                <li>
                    <a href={twitterShare} title={t('Share on Twitter')} target="_blank" rel="noreferrer">
                        <TwitterLogo />
                    </a>
                </li>
            </ul>
        </StyledShareContainer>
    );
};

ShareContentButtons.propTypes = {
    displayText: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    shareText: PropTypes.string,
};

ShareContentButtons.defaultProps = {
    shareText: '',
};


/*
 * Exports
 */
export default ShareContentButtons;
