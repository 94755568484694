import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import {breakpoints, ContentContainer, fonts} from '../../../styles/theme';


/*
 * Private Elements
 */
const StyledBlogPostWrapper = styled(ContentContainer)`
    padding: 0 25px;
    position: relative;
    font-family: ${fonts.sansSerif};

    .extra-info {
        position: relative;
    }

    @media (min-width: ${breakpoints.md}) {
        padding-left: 65px;
        padding-right: 65px;

        .extra-info {
            padding-right: 1.5rem;
        }
    }
`;


/*
 * Public Elements
 */
const BlogPostWrapper = ({extraInfo = null, className = '', children}) => (
    <StyledBlogPostWrapper className={className}>
        <div className="row no-gutters">
            <div className="col-12 col-md-2 order-2 order-md-1">
                {extraInfo && (
                    <div className="extra-info h-100">
                        {extraInfo}
                    </div>
                )}
            </div>
            <article className="col-12 col-md-8 order-1 order-md-2">
                {children}
            </article>
        </div>
    </StyledBlogPostWrapper>
);

BlogPostWrapper.propTypes = {
    extraInfo: PropTypes.node,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

BlogPostWrapper.defaultProps = {
    extraInfo: null,
    className: '',
};


/*
 * Exports
 */
export default BlogPostWrapper;
