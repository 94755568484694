/** @jsx jsx */
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import {getSrc} from 'gatsby-plugin-image';

import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import {ContrastContext} from '../helpers/context';
import {EmbeddedPodcast, EmbeddedVideo, EndOfPageCTA} from '../components/common/Clickables';
import Markdown2React from '../components/common/Markdown2React';
import SEO from '../components/common/SEO';
import ShareContentButtons from '../components/common/ShareContentButtons';
import {H2, H5, H5Mono, P2} from '../components/common/Typography';
import BlogPostWrapper from '../components/learn/blog-post/BlogPostWrapper';
import BlogPostHero from '../components/learn/blog-post/Hero';
import {caseStudyPostTypeFilter, podcastPostTypeFilter, webinarPostTypeFilter} from '../helpers/constants/learn';
import useSiteMetadata from '../helpers/hooks/useSiteMetaData';
import GeometricPatternBackground from '../images/geometric-pattern-bg.png';
import {breakpoints, colors, ContentContainer, fontWeights} from '../styles/theme';


/*
 * Constants
 */
const embeddedContentElement = {
    [webinarPostTypeFilter]: EmbeddedVideo,
    [podcastPostTypeFilter]: EmbeddedPodcast,
};


/*
 * Private Elements
 */
const StyledExtraInfo = styled.div`
    display: flex;
    flex-direction: column;

    .section {
        margin-bottom: 10px;

        .content {
            font-weight: ${fontWeights.semiBold};
        }
    }

    .share {
        margin-top: 20px;
    }

    @media (min-width: ${breakpoints.md}) {
        position: absolute;
        bottom: 40px;
        left: 0;

        .section {
            margin-bottom: 30px;
        }

        .share {
            margin-top: 50px;
        }
    }
`;

const ExtraInfo = ({post, pageUrl}) => {
    const {t} = useTranslation();

    return (
        <StyledExtraInfo>
            <div className="section">
                <P2><Trans>Written by</Trans></P2>
                <P2 className="content">{post.blog_post_author.name}</P2>
            </div>
            <div className="section">
                <P2><Trans>Date</Trans></P2>
                <P2 className="content">{post.publish_date}</P2>
            </div>
            <ShareContentButtons
                displayText={t('Share this Article')}
                shareUrl={pageUrl}
                shareText={post.title}
            />
        </StyledExtraInfo>
    );
};

ExtraInfo.propTypes = {
    post: PropTypes.object.isRequired,
    pageUrl: PropTypes.string.isRequired,
};

const BlogPostBody = styled.div`
    .blog-post-description {
        margin: 80px 0;
    }

    .blog-post-content {
        margin-bottom: 80px;

        .embedded-element {
            margin-top: 50px;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        .blog-post-content {
            padding-left: 30px;
        }
    }

    @media (min-width: ${breakpoints.lg}) {
        .blog-post-content {
            padding-left: 50px;
        }
    }
`;


const ContactSalesCTAStyles = css`
    margin-top: 50px;

    ${ContentContainer} {
        .cta {
            margin-bottom: 0;

            ${H2} {
                margin-bottom: 20px;

                .thin {
                    font-weight: ${fontWeights.semiLight};
                }
            }
        }
    }

    .cta-bottom-background {
        background-color: ${colors.transparent};
    } 
`;


const BlogPostBackground = styled.div`
    padding-bottom: 50px;
    background: linear-gradient(330deg, rgba(255, 255, 255, 0.55) 0%, ${colors.white} 796px),
                right bottom/auto 800px url(${GeometricPatternBackground});
    background-size: contain;
`;


/*
 * Public Elements
 */
const BlogPostPage = ({data, location}) => {
    const setContrast = useContext(ContrastContext);
    setContrast(true);
    const {t} = useTranslation();

    // Build this page URL to share
    const pageUrl = useSiteMetadata().siteUrl + location.pathname;

    const post = data.strapiBlogPost;
    const EmbeddedElement = embeddedContentElement[post.type];

    const extraInfo = <ExtraInfo post={post} pageUrl={pageUrl} />;
    const postDescription = post.description ? <H5 className="blog-post-description">{post.description}</H5> : null;
    const embeddedElement = EmbeddedElement ? <EmbeddedElement iframeSource={post.extra[0].link} /> : null;

    const endOfPageCTA = post.type === caseStudyPostTypeFilter ? (
        <EndOfPageCTA link="/about/contact-sales" linkText={t('Contact Sales')} cssStyles={ContactSalesCTAStyles}>
            <H2>
                <Trans>
                    <span className="thin">Do you think Switch can help</span>
                    {' '}
                    your business
                    {' '}
                    <span className="thin">too?</span>
                </Trans>
            </H2>
            <H5Mono><Trans>Our team is here to help you integrate.</Trans></H5Mono>
        </EndOfPageCTA>
    ) : null;

    return (
        <>
            <SEO
                title={post.title}
                ogTitle={`${post.title} - Switch`}
                description={post.description}
                image={getSrc(post.feature_image.localFile)}
                isArticle
            />

            <BlogPostHero post={post} />

            <BlogPostBackground>
                <BlogPostWrapper extraInfo={extraInfo}>
                    <BlogPostBody>
                        {postDescription}

                        <div className="blog-post-content">
                            <Markdown2React content={post.content} contentImages={post.content_images} />

                            {embeddedElement}
                        </div>
                    </BlogPostBody>
                </BlogPostWrapper>

                {endOfPageCTA}
            </BlogPostBackground>
        </>
    );
};

BlogPostPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default BlogPostPage;
export const query = graphql`
    query($postId: String!) {
        strapiBlogPost(strapiId: { eq: $postId }) {
            id: strapiId
            title
            sub_title
            description
            content
            publish_date(formatString: "DD MMMM YYYY")
            type
            blog_tags {
                tag
            }
            blog_post_author {
                name
            }
            feature_image {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            width: 1000
                        )
                    }
                }
            }
            extra {
                link
                file {
                    localFile {
                        publicURL
                    }
                }
            }
            content_images: childrenFile {
                name
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        width: 1000
                    )
                }
            }
        }
    }
`;
