import React from 'react';
import PropTypes from 'prop-types';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import {H1, H2, H3, H4, H5, H6, Link, P, Figcaption} from './Typography';
import {colors, fontWeights} from '../../styles/theme';


/*
 * Constants
 */
const headingElementsMatch = {
    1: H1,
    2: H2,
    3: H3,
    4: H4,
    5: H5,
    6: H6,
};
const plugins = [
    [gfm, {singleQuote: false}],
];
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
const renderers = images => ({
    link: ({node, children, ...props}) => <Link {...props} target="_blank">{children}</Link>,
    paragraph: ({node, children, ...props}) => <P {...props}>{children}</P>,
    heading: ({node, level, children, ...props}) => {
        const Element = headingElementsMatch[level];
        return <Element {...props}>{children}</Element>;
    },
    image: ({node, children, src, alt, title, ...props}) => {
        // TODO: fix div inside paragraph validation error
        // Iterate the available images on this post, to find the one that matches the source we are looking for
        for (let i = 0; i < images.length; i++) {
            if (src.includes(images[i].name)) {
                return (
                    <figure>
                        <GatsbyImage
                            as="span"
                            image={getImage(images[i].childImageSharp)}
                            alt={alt}
                            {...props}
                        />
                        {title && <Figcaption>{title}</Figcaption>}
                    </figure>
                );
            }
        }

        return null;
    },
});
/* eslint-enable react/prop-types */
/* eslint-enable no-unused-vars */


/*
 * Private Elements
 */
const StyledMarkdown2React = styled.div`
    ${Link} {
        color: ${colors.darkPurple};
        font-weight: ${fontWeights.medium};
    }

    ${H1},
    ${H2},
    ${H3},
    ${H4},
    ${H5},
    ${H6} {
        font-weight: ${fontWeights.medium};
        margin-bottom: 15px;
    }

    ${H1} {
        margin-top: 60px;
        font-size: 36px;
        line-height: 46px;
    }

    ${H2} {
        margin-top: 50px;
        font-size: 30px;
        line-height: 40px;
    }

    ${H3} {
        margin-top: 40px;
    }

    ${H4} {
        margin-top: 30px;
    }

    ${H5} {
        margin-top: 20px;
    }

    ${H6} {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    ${P} {
        margin-bottom: 32px;
        line-height: 26px;
    }

    ol,
    ul {
        margin-bottom: 32px;

        li {
            margin-bottom: 15px;
            line-height: 26px;
        }
    }

    blockquote {
        position: relative;
        padding-left: 15px;
        margin-left: 5px;

        &::before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 100%;
            width: 2px;
            background: linear-gradient(to bottom, ${colors.darkPurple}, ${colors.black});
        }

        * {
            color: ${colors.darkPurple};
            font-size: 21px;
            line-height: 28px;
        }
    }

    strong {
        font-weight: 600;
    }
`;


/*
 * Public Elements
 */
const Markdown2React = ({content, contentImages}) => (
    <StyledMarkdown2React>
        <ReactMarkdown plugins={plugins} renderers={renderers(contentImages)} allowDangerousHtml>
            {content}
        </ReactMarkdown>
    </StyledMarkdown2React>
);

Markdown2React.propTypes = {
    content: PropTypes.string.isRequired,
    contentImages: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};


/*
 * Exports
 */
export default Markdown2React;
