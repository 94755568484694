/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';

import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {Trans} from 'gatsby-plugin-react-i18next';
import {getImage} from 'gatsby-plugin-image';
import {BgImage} from 'gbimage-bridge';

import {breakpoints, colors, fonts, fontWeights} from '../../../styles/theme';
import {CTALink, H1, H5, heroBaseStyle} from '../../common/Typography';
import {caseStudyPostTypeFilter, postTypeFilters} from '../../../helpers/constants/learn';
import DownloadIcon from '../../../images/download.svg';


/*
 * Private Elements
 */
const StyledBlogPostHero = styled.div`
    padding-top: 70px;
    margin-bottom: 35px;
    min-height: 300px;
    max-height: 800px;

    display: grid;
    grid-template-columns: 25px 1fr 25px;
    grid-template-rows: 20vh 50px 1fr;
  
    .hero-content {
        grid-column: 1 / 3;
        grid-row: 2 / -1;
        background-color: ${colors.white};
        z-index: 1;
        padding: 35px 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        svg {
            * {
                stroke: ${colors.darkPurple};
            }
        }

        .top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: ${colors.darkPurple};
            font-family: ${fonts.monospace};
            font-weight: ${fontWeights.semiBold};
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 35px;

            .category {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                }

                p {
                    display: flex;
                    align-items: center;
                    margin: 0;
                    font-size: 12px;
                    line-height: 22px;
                }
            }
        }

        ${H5} {
            margin: 0;
        }

        .download-link {
            margin-top: 0.5rem;

            svg {
                margin-left: 10px;
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        padding-top: 110px;
        height: 85vh;
        min-height: 500px;
        max-height: 800px;
        grid-template-columns: 20% 1fr 45%;
        grid-template-rows: 1fr 80%;

        .hero-content {
            padding: 45px 55px 55px max(calc(((100vw - 1400px) / 2) + 115px), 115px);
            border-top-right-radius: 5px;
        }
    }
    
  @media (min-width: ${breakpoints.xl}) {
      .hero-content {
          ${H1} {
              ${heroBaseStyle};
          }
      }
  }
`;

const backgroundImageStyles = css`
    grid-column: 2 / -1;
    grid-row: 1 / 3;

    &::before,
    &::after {
        border-radius: 5px;
    }
`;


/*
 * Public Elements
 */
const BlogPostHero = ({post}) => {
    const downloadLink = post.type === caseStudyPostTypeFilter ? (
        <CTALink
            className="download-link"
            to={post.extra[0].file.localFile.publicURL}
            gaLabel={`Case Study - ${post.title}`}
            download
        >
            <Trans>Download Case Study</Trans>
            <DownloadIcon />
        </CTALink>
    ) : null;

    return (
        <StyledBlogPostHero>
            <BgImage css={backgroundImageStyles} image={getImage(post.feature_image.localFile)} />

            <div className="hero-content">
                <div className="top">
                    <div className="category">
                        {postTypeFilters[post.type].icon}
                        <p>{postTypeFilters[post.type].name}</p>
                    </div>
                    <div className="labels">{post.blog_tags[0]?.tag}</div>
                </div>
                <H1>{post.title}</H1>
                <H5>{post.sub_title}</H5>

                {downloadLink}
            </div>
        </StyledBlogPostHero>
    );
};

BlogPostHero.propTypes = {
    post: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default BlogPostHero;
